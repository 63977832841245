import { LanguageToggle } from '@arckit/ui'
import Resource from 'components/global/Resource'
import VideoEmbed from 'components/global/VideoEmbed'
import { Col, Row } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

const HowItWorks = () => {
	const [language, setLanguage] = useState<'english' | 'spanish'>('english')

	return (
		<Tabs selectedTabClassName="text-ab-100 border-ab-100">
			<TabList className="mb-6 flex flex-wrap">
				<Tab className="mx-4 mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base shadow-sm lg:text-lg">Reading Taxonomy</Tab>
				<Tab className="mx-4 mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base shadow-sm lg:text-lg">IRLA Validity</Tab>
				<Tab className="mx-4 mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base shadow-sm lg:text-lg">Online Performance Management</Tab>
				<Tab className="mx-4 mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base shadow-sm lg:text-lg">Text Complexity</Tab>
				{/* <Tab className="mx-4 mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base shadow-sm lg:text-lg">Research Basis</Tab>
				<Tab className="mx-4 mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base shadow-sm lg:text-lg">Results</Tab> */}
			</TabList>
			<TabPanel>
				<Row className="pt-3">
					<Col>
						<P className="mb-8">
							The IRLA is not intended to limit what students read. Rather, the reading taxonomy simplify the complexity of the reading process, allowing teachers to
							support every reader with whatever books that reader chooses. As one user said, "the IRLA takes the mystery out of teaching reading."
						</P>
						<div className="mb-8">
							<LanguageToggle onClickEnglish={() => setLanguage('english')} onClickSpanish={() => setLanguage('spanish')} language={language} />
						</div>
						<Resource noZoom title={`Reading on Ramp | ${language.charAt(0).toUpperCase() + language.slice(1)}`} />
						<p className="mt-3 text-center">Click to open as a PDF</p>
					</Col>
				</Row>
			</TabPanel>
			<TabPanel>
				<Row className="pt-3" center>
					<Col width="w-full md:w-1/4" className="mb-6">
						<Resource title="Validity of the IRLA - Summative" />
						<div className="mt-4 text-center">
							<span className="font-semibold">Study of the relationship between the IRLA and State ELA Tests</span>
						</div>
					</Col>
					<Col width="w-full md:w-1/4" className="mb-6">
						<Resource title="Validity of the IRLA" />
						<div className="mt-4 text-center">
							<span className="font-semibold">Study of the relationship between the IRLA and Interim ELA Assessments</span>
						</div>
					</Col>
					<Col width="w-full md:w-1/4" className="mb-6">
						<Resource title="Concurrent Validity of the IRLA" />
						<div className="mt-4 text-center">
							<span className="font-semibold">IRLA validity study published in The Journal of At–Risk Issues</span>
						</div>
					</Col>
					{/* <Col width="w-full md:w-1/4" className="mb-6">
						<Resource title="Measurement Inc. Report" />
						<div className="mt-4 text-center">
							<span className="text-xl font-medium">
								Study of IRLA validity and educational effectiveness conducted by <em>Measurement Incorporated</em>
							</span>
						</div>
					</Col> */}
				</Row>
			</TabPanel>
			<TabPanel>
				<Row className="pt-3">
					<Col>
						{/* <p>
						The IRLA and ENIL allow teachers and schools to track progress in real time.{' '}
							<Link to="/digital-solutions">
								SchoolPace<sup>®</sup>
							</Link>{' '}
							provides school leaders with real-time insights into student data at the school and district levels. With dashboards to track Key Metrics and drivers of
							success, SchoolPace empowers all stakeholders to identify where support and intervention are needed most.
						</p> */}
						{/* <p>
							With <Link to="/digital-solutions">SchoolPace</Link>, ARC's web-based student achievement software, powerful dashboards and reports assess student
							performance in real time at the classroom, school, and district levels and allow schools to use equity-driven data to transform instruction and
							outcomes.
						</p> */}
						<P>
							<Link to="/digital-solutions">
								SchoolPace<sup>®</sup>
							</Link>{' '}
							provides school leaders with insights into student data at the classroom, school, and district levels. With dashboards to track Key Metrics and drivers
							of success, SchoolPace empowers all stakeholders to identify where support and intervention are needed most. SchoolPace includes access to the eIRLA and
							eENIL, our online tools for measuring student achievement.
						</P>

						<VideoEmbed title="SchoolPace Connect for Schools" src="https://player.vimeo.com/video/491771258" />
					</Col>
				</Row>
			</TabPanel>
			<TabPanel>
				<Row className="px-8 pt-3" middle center>
					<Col width="w-1/2">
						<P>
							ARC has spent the last 20 years reviewing virtually every book in print from every publisher. Every book is double-blind hand-evaluated using the three
							legs of text complexity and located in our developmental taxonomy of reading acquisition and evaluated for representation and age appropriateness.
						</P>
					</Col>
					<Col width="w-1/2" className="flex justify-center">
						<StaticImage src="../../images/irla-enil/triangle.png" alt="triangle" />
					</Col>
				</Row>
				{/* <Row className="pt-3" center>
					<Col>
						<img src={data.textLeveling.file.url} alt={data.textLeveling.title} />
					</Col>
					<Col width="w-full lg:w-7/12">
						<OverlayVideo title="Classroom Libraries" />
					</Col>
				</Row> */}
			</TabPanel>
			{/* <TabPanel>
				<Row className="pt-3">
					<Col>
						<h3>Why a Structured Phonics Program is Effective</h3>
						<p>
							David Liben, Student Achievement Partners, and David D. Paige, Bellarmine University, explore the importance and effectiveness of structured
							phonics—specifically naming American Reading Company's IRLA and IRLA Toolkits as one of the structured phonics programs that gets it right.
						</p>
						<a href={data.structuredPhonics.file.url} target="_blank" rel="noopener noreferrer">
							<Button>Read Full Report</Button>
						</a>
						<h3 className="mt-12">Measurement Incorporated Supports Claims of IRLA Effectiveness</h3>
						<p>
							In a 2014 study conducted by Measurement Incorporated, an industry leader in delivering research and evaluation services to educational institutions,
							experts and practitioners alike agree that the IRLA® formative assessment framework does indeed offer a multitude of benefits for students, teachers,
							parents, and administrators.
						</p>
						<div className="flex">
							<a href={data.measurementInc.file.url} target="_blank" rel="noopener noreferrer">
								<Button>Read Full Report</Button>
							</a>
							<a href={data.tenClaims.file.url} target="_blank" rel="noopener noreferrer" className="ml-3">
								<Button>Read Full Report</Button>
							</a>
						</div>
					</Col>
				</Row>
			</TabPanel>
			<TabPanel>
				<Row className="pt-3">
					<Col>
						<h3>Results</h3>
						<p>
							The IRLA has demonstrated success in more than 5,000 schools, 1,000 districts, and 50 states, including the District of Columbia. Dramatic improvements
							in reading proficiency are evident in a variety of assessment results, including high-stakes standardized tests.
						</p>
						<p className="font-bold">Check out some of our highlights.</p>
						<ul>
							<li>
								<p className="mb-0 font-bold text-ab-100">Chatfield Elementary School, Chatfield Public Schools, MN</p>
								<p>Students in the bottom 15% of the school in reading went from 18% scoring proficient on the MCA exam to 88%.</p>
							</li>
							<li>
								<p className="mb-0 font-bold text-ab-100">Clinton Elementary School, Lancaster County School District, SC</p>
								<p>
									Grade 3 African American males scoring proficient or advanced increased 34 points on PASS ELA exam, outperforming the district and state
									averages.
								</p>
							</li>
							<li>
								<p className="mb-0 font-bold text-ab-100">Cramer College Preparatory Lab School, Camden City Public Schools, NJ</p>
								<p>Over 80% of students exited Kindergarten and first grade above their expected grade level equivalency on Gates-MacGinitie Assessment.</p>
							</li>
							<li>
								<p className="mb-0 font-bold text-ab-100">PS/MS 194X, New York City Public Schools, NY</p>
								<p>ELL Proficiency rates doubled from 13% to 33.3% in one year, while the district and city-wide rates dropped.</p>
							</li>
							<li>
								<p className="mb-0 font-bold text-ab-100">Palm Bay Elementary, Brevard Public Schools, FL</p>
								<p>Percentage of students in “Lowest 25% making learning gains in reading” increased from 56% to 80%.</p>
							</li>
							<li>
								<p className="mb-0 font-bold text-ab-100">Bristol Township School District, PA</p>
								<p>Bristol Township School District went from 28% proficient to 71.8% proficient from October to June—more than 40 points of growth.</p>
							</li>
							<li>
								<p className="mb-0 font-bold text-ab-100">Reading School District, PA</p>
								<p>Reading more than quadrupled the number of students proficient or above—going from 8.3% to 36.2%.</p>
							</li>
							<li>
								<p className="mb-0 font-bold text-ab-100">Three Rivers Elementary, TX</p>
								<p>
									Three Rivers started with 17.8% of students reading proficiently and ended the year with 55.5% proficient or above—almost 40 points of growth.
								</p>
							</li>
						</ul>
					</Col>
				</Row>
			</TabPanel> */}
		</Tabs>
	)
}

export default HowItWorks
