import Resource from 'components/global/Resource'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import HowItWorks from 'components/formative-assessment/HowItWorks'
import SubNav from 'components/SubNav'
import Layout from 'components/Layout'
import OverlayVideo from 'components/overlay-video'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import LargeForm from 'components/forms/LargeForm'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'

const subNavItems = [
	{ name: 'IRLA/ENIL', link: 'irla-enil' },
	{ name: 'Videos', link: 'videos' },
	{ name: 'MLL Guide', link: 'multilingual-learners' },
	{ name: 'How It Works', link: 'how-it-works' }
	// { name: 'Intervention', link: 'intervention' }
]

export default function LevelingOverviewPage() {
	return (
		<Layout pageName="Formative Assessment">
			<SubNav items={subNavItems} />
			<Section flatGray>
				<Row middle className="pb-12 pt-12 md:pb-16 md:pt-0">
					<Col width="w-full md:w-7/12">
						<div>
							<H1 className="mb-3">Formative Assessment & Differentiated Support</H1>
							<P>
								American Reading Company’s Independent Reading Level Assessment<sup>®</sup> (IRLA<sup>®</sup>) is a game-changing formative assessment for reading
								that changes the daily behaviors of teachers, students, and administrators.
							</P>
						</div>
					</Col>
					<Col width="w-full md:w-5/12" className="hidden md:block">
						<StaticImage src="../../static/images/formative-assessment/hero.png" placeholder="dominantColor" alt="formative assessment" />
					</Col>
				</Row>
			</Section>
			<Section id="irla-enil" scrollMarginTop="80px" margin="mt-20">
				<SectionTitle title="Formative Assessment" />
				<Row>
					<Col width="w-full sm:w-1/2" className="mb-8">
						<div className="flex h-full flex-col rounded-lg shadow">
							<div className="bg-gray-100 p-3">
								<Resource title="IRLA" noLink noZoom maxHeight={250} />
							</div>
							<div className="flex-grow p-6">
								<P size="md">
									The IRLA's Developmental Reading Taxonomy<sup>®</sup> begins with Reading On-Ramp, then proceeds through phonics and vocabulary & knowledge
									developmental sequences. The IRLA delivers specific, actionable data that tells the teacher where a student is, why, and the sequence of
									skills/behaviors needed to learn next to accelerate reading growth.
								</P>
							</div>
						</div>
					</Col>
					<Col width="w-full sm:w-1/2" className="mb-8">
						<div className="flex h-full flex-col rounded-lg shadow">
							<div className="bg-gray-100 p-3">
								<Resource title="ENIL | CCSS" noLink noZoom maxHeight={250} />
							</div>
							<div className="flex-grow p-6">
								<P size="md">
									The Evaluación del nivel independiente de lectura<sup>®</sup> (ENIL<sup>®</sup>) is a developmental reading taxonomy for students, paralleling
									the Independent Reading Level Assessment (IRLA) while reflecting how the stages of learning to read differ between Spanish and English.
								</P>
							</div>
						</div>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 lg:mt-40" id="videos" scrollMarginTop="80px">
				<SectionTitle
					title="Watch It in Action"
					text="Watch educators use the IRLA formative assessment to conference with students and determine what they know now and what they will learn next."
				/>
				<Row>
					<Col width="w-full md:w-1/2" className="mb-6">
						<OverlayVideo title="ARC Core K-12 Assessment" />
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6">
						<OverlayVideo title="Grade 8 Demo" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 lg:mt-40" id="multilingual-learners" scrollMarginTop="80px">
				<Row middle className="flex-col-reverse md:flex-row">
					<Col width="w-full md:w-5/12" className="mb-6">
						<div className="h-full w-full bg-gray-200 py-16 pl-0 md:pl-10">
							<div className="-mr-0 md:-mr-32">
								<Resource title="MLL Guide" noLink noZoom />
							</div>
						</div>
					</Col>
					<Col width="w-full md:w-7/12" className="pl-4 md:pl-40">
						<span className="mt-3 mb-1 inline-block text-3xl font-medium">
							The Guide to IRLA Coaching with Multilingual Learners<sup>©</sup>
						</span>

						<P size="md">
							The Guide to IRLA Coaching with Multilingual Learners is organized to provide high-leverage Power Goals specific to students who are developing reading
							skills as they acquire English-language proficiency, and vice versa.
						</P>
					</Col>
				</Row>
				<Row center className="mt-10">
					<Col width="w-full lg:w-7/12">
						<OverlayVideo title="Louidor" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 lg:mt-40" id="how-it-works" scrollMarginTop="80px">
				<SectionTitle title="How It Works" />
				<Row>
					<Col>
						<HowItWorks />
					</Col>
				</Row>
			</Section>
			{/* <Section id="intervention" margin="mt-40" scrollMarginTop="80px">
				<Row>
					<Col>
						<Intervention />
					</Col>
				</Row>
			</Section> */}
			<Section margin="mt-20 lg:mt-40">
				<Row className="justify-center pb-6">
					<Col width="w-full md:w-5/6 lg:w-3/4 xl:w-1/2">
						<LargeForm pageName="Formative Assessment" />
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}
